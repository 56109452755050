import * as Yup from "yup"
import _form from "utils/form"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

export const useSidebarForm = ({ defaultValues = {}, fields }) => {
	const validationFields = _form.fieldsValidation(fields)
	const validation = Yup.object().shape(validationFields)

	const {
		watch,
		control,
		setValue,
		getValues,
		handleSubmit,
		formState: { errors, isValid, ...props },
		...form
	} = useForm({
		defaultValues: defaultValues,
		mode: "onChange",
		...(validation && { resolver: yupResolver(validation) }),
	})

	watch()

	return {
		...form,
		watch,
		errors,
		control,
		isValid,
		setValue,
		getValues,
		handleSubmit,
		validationFields,
	}
}
