import * as Yup from "yup"
import { _validators, _array } from "@redsales/ds/utils"

export const fieldsValidations = {
	name: Yup.string(),
	description: Yup.string(),
	full_name: Yup.string(),
	cpf: Yup.string().test("validDocument", "Documento inválido", (value) =>
		_validators.cpf(value)
	),

	email: Yup.string().test(
		"validDocument",
		"Digite um email válido",
		(value) => !value || _validators.email(value)
	),

	phone: Yup.string().test(
		"validPhoneNumber",
		"Telefone inválido",
		(value) => !value || _validators.phone(value)
	),

	product_ids: Yup.array(),
	role_ids: Yup.array(),
	team_id: Yup.string(),

	postal_code: Yup.string().test(
		"validPostalCode",
		"CEP inválido",
		(value) => !value || _validators.cep(value)
	),

	agent_id: Yup.string(),

	group_id: Yup.string(),
	profile_id: Yup.string(),
	blocking_reason: Yup.string(),
	segment: Yup.string(),

	employee_id: Yup.string(),
}
