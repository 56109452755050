export const MODAL_STATE = {
	HIDDEN: "HIDDEN",
	CREATE: "CREATE",
	EDIT: "EDIT",
}

export const TABLE_INFO = {
	infoList: [
		{
			key: "name",
			name: "name",
			label: "Nome do usuário",
			is_sortable: true,
			type: "DEFAULT",
			primary: true,
			hidden: false,
			order: 1,
			text: "Nome do usuário",
			scroll_lock: "left",
		},
		{
			key: "email",
			name: "email",
			label: "E-mail do usuário",
			is_sortable: true,
			type: "DEFAULT",
			primary: false,
			hidden: false,
			order: 2,
			text: "E-mail do usuário",
			scroll_lock: "",
		},
		{ text: "Ações", label: "Ações", scroll_lock: "" },
	],
	info: {
		0: {
			key: "name",
			name: "name",
			label: "Nome do usuário",
			is_sortable: true,
			type: "DEFAULT",
			primary: true,
			hidden: false,
			order: 1,
			text: "Nome do usuário",
			scroll_lock: "left",
		},
		1: {
			key: "email",
			name: "email",
			label: "E-mail do usuário",
			is_sortable: true,
			type: "DEFAULT",
			primary: false,
			hidden: false,
			order: 2,
			text: "E-mail do usuário",
			scroll_lock: "",
		},
	},
	infos: {
		0: {
			key: "name",
			name: "name",
			label: "Nome do usuário",
			is_sortable: true,
			type: "DEFAULT",
			primary: true,
			hidden: false,
			order: 1,
			text: "Nome do usuário",
			scroll_lock: "left",
		},
		1: {
			key: "email",
			name: "email",
			label: "E-mail do usuário",
			is_sortable: true,
			type: "DEFAULT",
			primary: false,
			hidden: false,
			order: 2,
			text: "E-mail do usuário",
			scroll_lock: "",
		},
	},
	order: { full_name: "asc" },
	columnQuantity: 2,
}

export const USERS_MOCK = {
	data: [
		{
			user_id: "6441b7019b0d24bbb11c7aaa",
			full_name: "Bruno Annunciato",
			email: "bannunciato@redventures.com",
			email_verified_at: "2023-04-20T22:09:29+0000",
			password_expires_at: "2023-07-19T22:09:29+0000",
			is_power_user: false,
			timezone: "America/Sao_Paulo",
			date_format: "02/01/2006",
			time_format: "15:04",
			locale: "pt-BR",
			first_day_of_week: 0,
			team: {
				team_id: "643845a6b3be06c798fd38ad",
				name: "Red Ventures",
				description: "",
				products: null,
				users_count: 0,
				created_at: "2023-04-13T18:10:46+0000",
			},
			roles: [
				{
					role_id: "643962375a53e15fd0490e57",
					name: "Superintendente",
					created_at: "2023-04-14T14:24:55+0000",
				},
			],
			permissions: [
				"view all leads",
				"view all reports",
				"edit own leads",
				"create products revoked",
				"use kanban granted",
				"use whatsapp revoked",
				"manage all users permissions granted",
				"transfer leads to teams granted",
				"export leads revoked",
				"create roles revoked",
				"view all activities",
				"create activities granted",
				"create users granted",
				"create teams granted",
				"view all users",
				"manage team users permissions granted",
				"associate leads to users revoked",
				"view all products",
				"import leads revoked",
				"edit all teams",
				"edit all users",
				"manage all users status granted",
				"view all teams",
				"transfer leads to user granted",
				"create leads revoked",
				"take leads from queue revoked",
				"manage team users status granted",
			],
			account_activated_at: "2023-04-20T22:09:29+0000",
			last_active_at: "2023-04-20T22:10:21+0000",
			created_at: "2023-04-20T22:04:50+0000",
			status: { text: "Ativo", color: "green" },
		},
		{
			user_id: "643d902ef9abffb9b4f5e0c4",
			full_name: "Beatriz Gomes",
			email: "beatriz.tiburciog@gmail.com",
			email_verified_at: "2023-04-17T18:46:42+0000",
			password_expires_at: "2023-07-16T18:46:42+0000",
			is_power_user: false,
			timezone: "America/Sao_Paulo",
			date_format: "02/01/2006",
			time_format: "15:04",
			locale: "pt-BR",
			first_day_of_week: 0,
			team: {
				team_id: "643845a6b3be06c798fd38ad",
				name: "Red Ventures",
				description: "",
				products: null,
				users_count: 0,
				created_at: "2023-04-13T18:10:46+0000",
			},
			roles: [
				{
					role_id: "643962375a53e15fd0490e56",
					name: "Gerente",
					created_at: "2023-04-14T14:24:55+0000",
				},
			],
			permissions: [
				"view team leads",
				"manage team users status granted",
				"associate leads to users granted",
				"edit team leads",
				"manage team users permissions granted",
				"manage all users status revoked",
				"view team reports",
				"view team activities",
				"create leads revoked",
				"create roles revoked",
				"view own teams",
				"manage all users permissions revoked",
				"use kanban granted",
				"create activities granted",
				"transfer leads to teams revoked",
				"edit own teams",
				"create teams revoked",
				"edit team users",
				"transfer leads to user granted",
				"create products revoked",
				"import leads revoked",
				"take leads from queue revoked",
				"create users granted",
				"export leads revoked",
				"view team users",
				"use whatsapp revoked",
				"view all products",
			],
			account_activated_at: "2023-04-17T18:46:42+0000",
			last_active_at: "2023-04-20T21:52:59+0000",
			created_at: "2023-04-17T18:30:06+0000",
			status: { text: "Ativo", color: "green" },
		},
	],
	meta: { total_count: 2, total_pages: 1, per_page: 10, page: 1 },
}
