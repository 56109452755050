import './TableAction.scss'
import { USER_STATUS } from './constants'
import { Icon, Hint } from '@redsales/ds'
import Can from 'components/Can'
import If from 'components/If'
import { PERMISSIONS } from 'constants/permissions'

const TableAction = ({ user, onEdit = () => {}, onDelete = () => {} }) => (
	<div className="users-table-actions__container">
		<Can do={[PERMISSIONS.USER.EDIT]}>
			<button
				onClick={() => onEdit(user)}
				className="users-table-actions__hint--container"
			>
				<Icon name="file-edit" className="users-table-actions__icon" />
				<Hint
					hasAutoWidth
					isSideways={true}
					balloon="right-side"
					className="users-table-actions__hint"
				>
					Editar
				</Hint>
			</button>
			<If
				condition={USER_STATUS.ACTIVE.includes(user?.status?.text)}
				renderIf={
					<>
						<button
							className="users-table-actions__hint--container"
							onClick={() => onToggleBlock(user)}
						>
							<Icon name="trash" className="users-table-actions__icon" />
							<Hint
								hasAutoWidth
								isSideways={true}
								balloon="right-side"
								className="users-table-actions__hint"
							>
								Bloquear
							</Hint>
						</button>
					</>
				}
				renderElse={
					<>
						<button
							disabled={!USER_STATUS.ABSENT.includes(user?.status?.text)}
							className="users-table-actions__hint--container"
							onClick={() => onToggleAbsent(user)}
						>
							<Icon
								name="calendar-x"
								className="users-table-actions__icon icon__alternative"
							/>
							<If condition={USER_STATUS.ABSENT.includes(user?.status?.text)}>
								<Hint
									hasAutoWidth
									isSideways={true}
									balloon="right-side"
									className="users-table-actions__hint hint__bigger"
								>
									Desmarcar ausência
								</Hint>
							</If>
						</button>
						<button
							disabled={!USER_STATUS.DISABLED.includes(user?.status?.text)}
							className="users-table-actions__hint--container"
							onClick={() => onToggleBlock(user)}
						>
							<Icon
								name="unlock"
								className="users-table-actions__icon icon__alternative"
							/>
							<If condition={USER_STATUS.DISABLED.includes(user?.status?.text)}>
								<Hint
									hasAutoWidth
									isSideways={true}
									balloon="right-side"
									className="users-table-actions__hint"
								>
									Desbloquear
								</Hint>
							</If>
						</button>
					</>
				}
			/>
		</Can>
	</div>
)

export default TableAction
