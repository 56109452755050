import { useCallback, useEffect, useState, useRef } from "react"
import { _browser, _object } from "@redsales/ds/utils"

import auth from "utils/auth"
import services from "services"
import { MODAL_STATE, TABLE_INFO, USERS_MOCK } from "./constants"
import { useParameters } from "hooks/useParameters"

const useUsers = () => {
	const { current_page: currentPage } = useParameters("current_page")

	const [users, setUsers] = useState([])
	const [header, setHeader] = useState({})
	const [viewRules, setViewRules] = useState({})
	const [pagination, setPagination] = useState({ total_pages: 1, page: 1 })

	const [sidebarInfo, setSidebarInfo] = useState({
		state: MODAL_STATE.HIDDEN,
		user: null,
	})

	const queries = useRef({})
	const currentUser = auth.getUser()

	const setQueries = (values) => {
		queries.current = { ...queries.current, ...values }
	}

	const closeSidebar = () => {
		setSidebarInfo({
			state: MODAL_STATE.HIDDEN,
			user: null,
		})
	}

	const onEditUser = (userProps) => {
		setSidebarInfo({
			state: MODAL_STATE.EDIT,
			userInfo: userProps,
		})
	}

	const onDelete = ({ user_id }) => {
		// DATA-BRIDGE: Criar serviço para deletar usuário
	}

	const onFilterByName = _browser.debounce((value) => {
		const filterData = { search: value, page: "1" }
		fetchUsers(filterData)
	}, 1000)

	const handleFetch = useCallback(({ data, meta }) => {
		setUsers(data)
		setPagination(Object(meta))
	}, [])

	const handleTableFetch = useCallback(
		({ error = false, ...tableInfo } = { error: true }) => {
			if (!error) {
				setHeader(tableInfo)
			}
		},
		[]
	)

	const getUsersFromAPIByTeam = (props) =>
		services.user.team(
			{
				user_id: currentUser?.user_id,
				page: currentPage || "",
				...queries.current,
				...props,
			},
			{ hideLoader: false }
		)

	const getUsersFromAPI = (props) =>
		services.user.list(
			{
				user_id: currentUser?.user_id,
				page: currentPage || "",
				...queries.current,
				...props,
			},
			{ hideLoader: false }
		)

	const fetchUsers = useCallback(
		(props) => {
			setQueries(props)

			// DATA-BRIDGE: fazer a requisição dos dados com e esem filtro, como abaixo:
			// if (!!teamId) {
			// 	return getUsersFromAPIByTeam(props).then(handleFetch)
			// }

			getUsersFromAPI(props).then((data) => {
				handleFetch(data)
			})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[currentUser?.name, currentPage, handleFetch]
	)

	const fetchTableRules = useCallback(() => {
		handleTableFetch(TABLE_INFO)
	}, [handleTableFetch])

	const handleViewFetch = useCallback(
		({ error = false, ...viewInfo } = { error: true }) => {
			if (!error) {
				const formattedFields = viewInfo?.fields.reduce((acc, curr) => {
					return !!curr?.hidden ? [...acc] : [...acc, curr]
				}, [])
				setViewRules(formattedFields)
			}
		},
		[]
	)

	const fetchViewRules = useCallback(
		(params) => {
			services.view.users({ params }).then(handleViewFetch)
		},
		[handleViewFetch]
	)

	const addUser = () => {
		setSidebarInfo({
			state: MODAL_STATE.CREATE,
			user: null,
		})
	}

	useEffect(() => {
		fetchUsers()
		fetchTableRules()
		fetchViewRules()
	}, [fetchUsers, fetchViewRules, fetchTableRules])

	return {
		sidebar: {
			...sidebarInfo,
			close: closeSidebar,
			formFields: viewRules,
		},

		pagination,
		onFilterByName,
		user: {
			header,
			list: users,
			addUser: addUser,
			onEdit: onEditUser,
			onDelete,
			headerItems: header?.infoList,
			isVisible: users?.length > 0 && !_object.isEmpty(header),
		},
		refresh: fetchUsers,
	}
}
export default useUsers
