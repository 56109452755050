import { MODAL_STATE } from '../../constants'

export const TITLE_BY_OUTSIDE = {
	undefined: '',
	[MODAL_STATE.HIDDEN]: '',
	[MODAL_STATE.EDIT]: 'Editar usuário',
	[MODAL_STATE.CREATE]: 'Adicionar usuário',
}

export const ICON_BY_OUTSIDE = {
	undefined: 'user-add',
	[MODAL_STATE.EDIT]: 'user',
	[MODAL_STATE.CREATE]: 'user-add',
	[MODAL_STATE.HIDDEN]: 'user-add',
}

export const SUBMIT_ENDPOINT_BY_STATE = {
	[MODAL_STATE.CREATE]: 'add',
	[MODAL_STATE.EDIT]: 'edit',
}

export const SUBMIT_RESPONSE = {
	[MODAL_STATE.CREATE]: 'Usuário adicionado!',
	[MODAL_STATE.EDIT]: 'Usuário atualizado!',
}

export const FORM_FIELDS = [
	{
		name: 'name',
		label: 'Nome',
		order: 1,
		hidden: false,
		hasEdit: true,
		hasCreate: true,
		valueType: 'DEFAULT',
		required: true,
		validateType: 'name',
		fieldType: 'INPUT_TEXT',
	},
	{
		name: 'email',
		label: 'E-mail',
		order: 3,
		hidden: false,
		hasEdit: true,
		hasCreate: true,
		valueType: 'DEFAULT',
		required: true,
		validateType: 'email',
		fieldType: 'INPUT_TEXT',
	},
	{
		name: 'role_id',
		label: 'Perfil',
		order: 5,
		hidden: false,
		options: [
			{ label: 'Administrador', value: 2 },
			{ label: 'Usuário', value: 3 },
		],
		hasEdit: true,
		hasCreate: true,
		valueType: 'DEFAULT',
		required: true,
		validateType: 'role_id',
		fieldType: 'SELECT_SEARCH',
	},
	{
		name: 'base_type_ids',
		label: 'Tipos de base',
		order: 6,
		hidden: false,
		options: [],
		hasEdit: true,
		hasCreate: true,
		valueType: 'ARRAY',
		required: true,
		validateType: 'base_type_ids',
		fieldType: 'SELECT_MULTIPLE',
	},
	{
		name: 'allowed_base_ids',
		label: 'Bases',
		order: 7,
		hidden: false,
		options: [],
		hasEdit: true,
		hasCreate: true,
		valueType: 'ARRAY',
		required: true,
		validateType: 'allowed_base_ids',
		fieldType: 'CHECKBOX_DEFAULT',
	},
]
