import { fieldsValidations } from "utils/fieldsValidations"

const insertOptionsIntoFields = ({ fields = [], options = [], name = "" }) => {
	const fieldIndex = fields?.findIndex((field) => field.name === name)

	if (fieldIndex > -1) {
		fields[fieldIndex] = {
			...fields[fieldIndex],
			options,
		}
	}

	return fields
}

const insertRequiredRule = ({
	required = false,
	valueType = "DEFAULT",
	validation,
}) => {
	if (required && valueType === "ARRAY") {
		return validation?.test
			? validation?.test({
					message: "Campo obrigatório",
					test: (arr) => arr?.length > 0,
			  })
			: validation
	}

	if (required) {
		return validation?.required
			? validation?.required("Campo obrigatório")
			: validation
	}

	return validation?.notRequired ? validation?.notRequired() : validation
}

const fieldsValidation = (fields = []) => {
	const validationFields = fields.reduce((otherFields, currentField) => {
		return currentField["hidden"]
			? { ...otherFields }
			: {
					...otherFields,
					[currentField?.name]: insertRequiredRule({
						...currentField,
						validation: fieldsValidations[currentField?.validateType],
					}),
			  }
	}, {})

	return validationFields
}

export default {
	fieldsValidation,
	insertOptionsIntoFields,
}
