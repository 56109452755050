import { ICON_BY_OUTSIDE, TITLE_BY_OUTSIDE, FORM_FIELDS } from './constants'
import useSidebar from './hooks'
import { Sidebar, Fields } from '@redsales/ds'
import If from 'components/If'

const SidebarHandleUser = ({
	refresh,
	userInfo = null,
	formFields = [],
	state: outsideState,
	closeSidebar = () => {},
	...props
}) => {
	const {
		form: { handleSubmit, isValid, control },
		onSubmit,
		fields,
		tryCancel,
		isVisible,
		options,
		onBasetypeChange,
		onBaseChange,
	} = useSidebar({
		outsideState,
		closeSidebar,
		formFields: FORM_FIELDS,
		userInfo,
		refresh,
	})

	return (
		<Sidebar
			size="short"
			isVisible={isVisible}
			closeSidebar={tryCancel}
			title={TITLE_BY_OUTSIDE[outsideState]}
			titleIcon={ICON_BY_OUTSIDE[outsideState]}
			className="access-management-sidebar-form"
			buttons={[
				{
					text: 'Cancelar',
					variant: 'ghost',
					color: 'gray',
					type: 'button',
					onClick: tryCancel,
				},
				{
					text: 'Aplicar',
					type: 'submit',
					form: 'access-management-sidebar-user',
					onClick: handleSubmit(onSubmit),
					disabled: !isValid,
				},
			]}
		>
			<form
				id="access-management-sidebar-user"
				className="access-management-sidebar-form"
				onSubmit={handleSubmit(onSubmit)}
			>
				<h2 className="access-management-sidebar__title">
					Informações do usuário
				</h2>

				<Fields.Input.Text
					name="name"
					label="nome"
					control={control}
					required
				/>

				<Fields.Input.Text
					name="email"
					label="E-mail"
					control={control}
					disabled={outsideState === 'EDIT'}
					required
				/>

				<Fields.Select.Search
					value={outsideState === 'EDIT' ? userInfo.role.id : null}
					name="role"
					options={fields.roleId?.options}
					label="Perfil"
					control={control}
					required
					disabled={outsideState === 'EDIT'}
				/>

				<p className="access-management-sidebar-form__label">Tipos de base</p>

				{options?.baseTypes.map((base) => {
					return (
						<Fields.Checkbox.Default
							key={base.value}
							name={`base_type_ids-${base.value}`}
							value={base.value}
							label={base.label}
							control={control}
							onChange={onBasetypeChange}
						/>
					)
				})}

				<p className="access-management-sidebar-form__label">bases</p>

				<If
					condition={options.bases.length > 0}
					renderIf={options?.bases.map((base) => {
						return (
							<Fields.Checkbox.Default
								key={base.value}
								name={`allowed_base_ids-${base.value}`}
								value={base.value}
								label={base.label}
								control={control}
								onChange={onBaseChange}
							/>
						)
					})}
					renderElse={'Selecione um ou mais tipos de base!'}
				/>
			</form>
		</Sidebar>
	)
}

export default SidebarHandleUser
