import { MODAL_STATE } from '../../constants'
import { SUBMIT_ENDPOINT_BY_STATE, SUBMIT_RESPONSE } from './constants'
import { useSidebarForm } from './form'
import { _message } from '@redsales/ds/utils'
import { useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import services from 'services'
import { BaseOptions } from 'services/options/entities'
import { filterFieldsByFormState } from 'utils/filters'
import _form from 'utils/form'

const useSidebar = ({
	outsideState,
	closeSidebar,
	formFields,
	userInfo,
	refresh,
}) => {
	const [options, setOptions] = useState({
		roles: [],
		bases: [],
		baseTypes: [],
	})
	const [selectedBaseTypes, setSelectedBaseTypes] = useState([])
	const [fetchedBaseTypes, setFetchedBaseTypes] = useState({})
	const [fieldsToRender, setFieldsToRender] = useState([])
	const [baseIds, setBaseIds] = useState([])
	const form = useSidebarForm({
		fields: filterFieldsByFormState({
			fields: fieldsToRender,
			state: outsideState,
		}),
	})

	const baseTypeField = useWatch({
		control: form.control,
		name: 'base_type_ids',
	})

	const tryCancel = () => {
		form?.reset({})
		closeSidebar()
	}

	const onSubmit = (formData) => {
		const userId = userInfo?.user_id

		const allowed_base_ids = baseIds
		const payload = { ...formData, allowed_base_ids }

		services.user[SUBMIT_ENDPOINT_BY_STATE[outsideState]]({
			payload,
			userId,
		})
			.then((e) => handleSubmit(e, outsideState))
			.catch((error) => {
				_message.showMessage({
					title: error.data?.message,
					variant: _message.variant.ERROR,
				})
			})
	}

	const handleSubmit = async ({ error }, submitState) => {
		if (!error) {
			_message.showMessage({
				title: SUBMIT_RESPONSE[submitState],
				variant: _message.variant.SUCCESS,
			})

			closeSidebar()
			form?.reset({})
			refresh()
		}
	}

	const handleFieldsToRender = () => {
		let fields = filterFieldsByFormState({
			fields: formFields,
			state: outsideState,
		})

		fields = _form.insertOptionsIntoFields({
			fields,
			options: options.bases,
			name: 'allowed_base_ids',
		})

		fields = _form.insertOptionsIntoFields({
			fields,
			options: options.baseTypes,
			name: 'base_type_ids',
		})

		setFieldsToRender(fields)
	}

	const getRoleIds = () => {
		return fieldsToRender.find((field) => {
			return field.name === 'role_id'
		})
	}

	const handleFetchDetails = (data) => {
		const formByTeam = {
			...data,
			role_id: data?.role?.id,
			allowed_base_ids: BaseOptions(data?.allowed_bases),
		}

		form?.reset(formByTeam)
	}

	const getCheckedBases = (data) => {
		const types = [
			...new Set(data?.allowed_bases.map((base) => base.base_type_id)),
		]

		const bases = [...new Set(data?.allowed_bases.map((base) => base.id))]

		return {
			bases,
			types,
		}
	}

	const fetchBaseOptions = (baseTypeId) => {
		return services.options.baseOptions({ baseTypeId })
	}

	useEffect(() => {
		handleFieldsToRender()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options.roles, options.bases, options.baseTypes])

	useEffect(() => {
		if (outsideState !== MODAL_STATE.HIDDEN) {
			services.options.baseTypes().then((baseTypeOptions) => {
				setOptions((prevOptions) => ({
					...prevOptions,
					baseTypes: baseTypeOptions,
				}))
			})
			services.options.role().then((roleOptions) => {
				setOptions((prevOptions) => ({ ...prevOptions, roles: roleOptions }))
			})

			if (outsideState === MODAL_STATE.EDIT) {
				services.user.get({ user_id: userInfo?.user_id }).then((data) => {
					const payload = { ...data }

					const checkedBases = getCheckedBases(data)

					setSelectedBaseTypes(checkedBases.types)

					checkedBases.types?.forEach((type) => {
						payload[`base_type_ids-${type}`] = true
					})

					checkedBases.bases?.forEach((base) => {
						payload[`allowed_base_ids-${base}`] = true
					})

					handleFetchDetails(payload)
				})
			}
		}
		handleFetchDetails()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [outsideState])

	useEffect(() => {
		if (!baseTypeField) return
		// const baseTypeIds = baseTypeField?.map((baseType) => baseType.value)
		setSelectedBaseTypes(baseTypeField)
	}, [baseTypeField])

	useEffect(() => {
		if (!selectedBaseTypes.length) return

		const bases = {}

		selectedBaseTypes.forEach(async (baseTypeId) => {
			if (fetchedBaseTypes[baseTypeId]) return

			const baseOptions = await fetchBaseOptions(baseTypeId)

			bases[baseTypeId] = baseOptions

			setFetchedBaseTypes({
				...fetchedBaseTypes,
				...bases,
			})
		})
	}, [selectedBaseTypes])

	useEffect(() => {
		if (!selectedBaseTypes.length) {
			return setOptions((prevOptions) => ({ ...prevOptions, bases: [] }))
		}

		const baseOptions = []
		selectedBaseTypes.forEach((baseTypeId) => {
			fetchedBaseTypes[baseTypeId] &&
				baseOptions.push(...fetchedBaseTypes[baseTypeId])
		})

		setOptions((prevOptions) => ({
			...prevOptions,
			bases: baseOptions,
		}))
	}, [fetchedBaseTypes, selectedBaseTypes])

	const onBasetypeChange = (_, value, event) => {
		if (event.target?.checked) {
			return setSelectedBaseTypes((state) => {
				return [...state, value]
			})
		}

		setSelectedBaseTypes((state) =>
			state.filter((base) => {
				return base !== value
			})
		)
	}

	const onBaseChange = (_, value, event) => {
		if (event.target?.checked) {
			return setBaseIds((state) => {
				return [...state, value]
			})
		}

		setBaseIds((state) =>
			state.filter((base) => {
				return base !== value
			})
		)
	}

	return {
		form,
		onSubmit,
		tryCancel,
		fieldsToRender,
		options,
		onBasetypeChange,
		onBaseChange,
		fields: {
			roleId: getRoleIds(),
		},
		isVisible:
			[MODAL_STATE.CREATE, MODAL_STATE.EDIT].includes(outsideState) &&
			fieldsToRender?.length > 0,
	}
}

export default useSidebar
