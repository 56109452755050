import { _object } from '@redsales/ds/utils'

export const prepareResetFilters = ({ filters = {}, resetValues = {} }) =>
	Object.entries(filters).reduce(
		(previous, [key]) => ({
			...previous,
			[key]: resetValues[key].value,
		}),
		{}
	)

export const transformFiltersIntoList = ({
	filters = {},
	resetValues = {},
	updateOne: updateOneFilter = (name, value) => {},
}) =>
	Object.entries(filters).reduce(
		(previous, [key, value]) =>
			[
				previous,
				{
					value,
					name: key,
					label: resetValues[key]?.text || key,
					onReset: () => updateOneFilter(key, resetValues[key]?.value || ''),
					isVisible: ![null, undefined, ''].includes(value),
				},
			].flat(),
		[]
	)

export const mapSubmitFilterBy = (
	submitFilter = {},
	submitAditionalInfo = {}
) => {
	if (!_object.isEmpty(submitFilter)) {
		return Object.entries(submitFilter)
			.map(([key, value]) => {
				if (!value) return []

				return {
					value: value || '',
					name: submitAditionalInfo[key]?.name,
					operator: submitAditionalInfo[key]?.operator,
				}
			})
			.flat()
	}

	return []
}

export const queryFilter = (list = []) => {
	return list?.reduce((previous, current, index) => {
		return `${previous}&rules[${index}][name]=${current?.name}&rules[${index}][operator]=${current?.operator}&rules[${index}][value]=${current?.value}`
	}, '')
}

export const filterFieldsByFormState = ({ fields = [], state = 'CREATE' }) => {
	const filteredFields = Array.isArray(fields)
		? fields.filter((field) => {
				if (!!field?.hidden) return false
				if (state === 'CREATE') return field?.hasCreate
				if (state === 'EDIT') return field?.hasEdit

				return true
		  })
		: []

	const handledEditFields = filteredFields.map((field) => {
		return {
			...field,
			disabled: field.name === 'role_id' || field.name === 'email',
		}
	})

	return state === 'EDIT' ? handledEditFields : filteredFields
}
