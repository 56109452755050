import '../AccessManagement.scss'
import SidebarHandleUser from './components/SidebarHandleUser'
import TableAction from './components/TableAction'
import useUsers from './hooks'
import {
	If,
	Table,
	Input,
	Button,
	Header,
	EmptyState,
	Pagination,
} from '@redsales/ds'
import Can from 'components/Can'
import { PERMISSIONS } from 'constants/permissions'

const Users = () => {
	const { user, sidebar, refresh, pagination, onFilterByName } = useUsers()

	return (
		<Can
			do={[
				PERMISSIONS.USER.CREATE_GRANTED,
				PERMISSIONS.USER.DELETE,
				PERMISSIONS.USER.EDIT,
				PERMISSIONS.USER.VIEW,
			]}
			otherwise={{ goTo: ROUTES.home.path }}
		>
			<div className="access-management">
				<Header
					title="Gestão de acessos"
					topContent={
						<div className="access-management__actions">
							<Input.Default
								className="access-management__search"
								type="search"
								placeholder="Buscar"
								rightIcon="search"
								onChange={(_, value) => onFilterByName(value)}
							/>

							<Can do={[PERMISSIONS.USER.CREATE_GRANTED]}>
								<Button
									leftIcon="user-add"
									size="medium"
									onClick={user.addUser}
								>
									Adicionar usuário
								</Button>
							</Can>
						</div>
					}
				/>

				<div className="access-management__container">
					<If
						condition={user.isVisible}
						renderIf={
							<>
								<div className="access-management__table">
									<Table.Body
										headerItems={user?.headerItems}
										header={user?.header}
									>
										{user.list?.map((userRow, index) => {
											return (
												<Table.ListItem key={`${userRow?.name}-${index}`}>
													<Table.Row contentForRow={userRow} />
													<Table.RowItem type="REACT">
														<TableAction user={userRow} {...user} />
													</Table.RowItem>
												</Table.ListItem>
											)
										})}
									</Table.Body>
								</div>
								<Pagination
									onChange={refresh}
									currentPage={pagination?.page}
									length={pagination?.total_pages}
								/>
							</>
						}
						renderElse={
							<EmptyState
								title="Nenhum usuário encontrado"
								description="Realize outro tipo de filtro"
							/>
						}
					/>
				</div>
			</div>

			<SidebarHandleUser
				{...sidebar}
				closeSidebar={sidebar?.close}
				refresh={refresh}
			/>
		</Can>
	)
}

export default Users
